.mainOverlay {
    position: absolute;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mainOverlay>div {
    z-index: 1;
    position: absolute;
    background: white;
    cursor: pointer;
    transition: height 300ms, width 300ms;
    animation: fadein 1000ms;
}
.mainOverlay>div:nth-of-type(odd) {
    height: 10vh;
    width: 2px;
}
.mainOverlay>div:nth-of-type(odd):hover {
    width: 100%;
}
.mainOverlay>div:nth-of-type(even) {
    width: 10vh;
    height: 2px;
}
.mainOverlay>div:nth-of-type(even):hover {
    height: 100%;
}
.mainOverlay>div:nth-of-type(1) {
    pointer-events: none;
    top: 0;
}
.mainOverlay>div:nth-of-type(2) {
    display: none;
    right: 0;
}
.mainOverlay>div:nth-of-type(3) {
    pointer-events: none;
    bottom: 0;
}
.mainOverlay>div:nth-of-type(4) {
    display: none;
    left: 0;
}
.spawnedText {
    position: absolute;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    font-weight: 100;
    z-index: 50;
    color: white;
    border: 1px solid white;
    border-radius: 100%;
    width: 15vmin;
    height: 15vmin;
    font-size: 3vmin;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    opacity: 0;
    transition: left 100ms linear, top 100ms linear, opacity 100ms linear;
    mix-blend-mode: difference;
}
.showSpawn {
    opacity: 1;
}
.tinyMenu {
    position: absolute;
    z-index: 1;
    color: white;
    top: 0;
    font-size: 10vmin;
    display: flex;
    align-items: center;
    width: 100%;
}
.tinyMenu>p {
    mix-blend-mode: difference;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 10vh;
    width: 10vw;
}
.tinyMenu>ul {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    font-size: 4vmin;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 10vh;
    width: 90vw;
    transform: translateX(-100vw);
    background: white;
    transition: all 200ms ease;
}
.tinyMenu>ul>li {
    color: black;
}
.tinyMenu>ul.showTinyMenu {
    transform: translateX(0);
}
@media screen and (min-width: 1025px) {
    .showSpawn {
        opacity: 1;
    }
    .mainOverlay>div:nth-of-type(1) {
        pointer-events: all;
    }
    .mainOverlay>div:nth-of-type(3) {
        pointer-events: all;
    }
    .mainOverlay>div:nth-of-type(2) {
        display: block;
    }
    .mainOverlay>div:nth-of-type(4) {
        display: block;
    }
    .tinyMenu {
        display: none;
    }
}