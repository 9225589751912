@import url(https://fonts.googleapis.com/css2?family=Kanit:wght@100;500&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  overflow: hidden;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(17,17,17);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
a {
  color: inherit;
  text-decoration: none;
}
.home {
  height: 100vh;
  width: 100vw;
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  mix-blend-mode: difference;
  font-family: 'Kanit', sans-serif;
}
.home>article>h1 {
  font-size: 14vmin;
  -webkit-animation: faderight 1000ms;
          animation: faderight 1000ms;
  -webkit-text-fill-color: white;
  /* -webkit-text-stroke: 2px white; */
  letter-spacing: 0.5vw;
  font-weight: 500;
  opacity: inherit;
}
.home>article>h3 {
  opacity: inherit;
  font-size: 6vmin;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  -webkit-animation: faderight 1500ms;
          animation: faderight 1500ms;
  font-weight: 100;
}
@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeup {
  0% {
      opacity: 0;
      -webkit-transform: translateY(5vh);
              transform: translateY(5vh);
  } 
  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
}
@keyframes fadeup {
  0% {
      opacity: 0;
      -webkit-transform: translateY(5vh);
              transform: translateY(5vh);
  } 
  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
}
@-webkit-keyframes fadedown {
  0% {
      opacity: 0;
      -webkit-transform: translateY(-5vh);
              transform: translateY(-5vh);
  } 
  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
}
@keyframes fadedown {
  0% {
      opacity: 0;
      -webkit-transform: translateY(-5vh);
              transform: translateY(-5vh);
  } 
  100% {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
  }
}
@-webkit-keyframes faderight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-5vw);
            transform: translateX(-5vw);
  } 
  100% {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0);
  }
}
@keyframes faderight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-5vw);
            transform: translateX(-5vw);
  } 
  100% {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0);
  }
}
@-webkit-keyframes fadeleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(5vw);
            transform: translateX(5vw);
  } 
  100% {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0);
  }
}
@keyframes fadeleft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(5vw);
            transform: translateX(5vw);
  } 
  100% {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0);
  }
}
@-webkit-keyframes home {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100vw);
            transform: translateX(100vw);
  }
}
@keyframes home {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100vw);
            transform: translateX(100vw);
  }
}
@-webkit-keyframes about {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(100vh);
            transform: translateY(100vh);
  }
}
@keyframes about {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(100vh);
            transform: translateY(100vh);
  }
}
@-webkit-keyframes portfolio {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-100vw);
            transform: translateX(-100vw);
  }
}
@keyframes portfolio {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-100vw);
            transform: translateX(-100vw);
  }
}
@-webkit-keyframes contact {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-100vh);
            transform: translateY(-100vh);
  }
}
@keyframes contact {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-100vh);
            transform: translateY(-100vh);
  }
}
.mainOverlay {
    position: absolute;
    height: 100vh;
    width: 100vw;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.mainOverlay>div {
    z-index: 1;
    position: absolute;
    background: white;
    cursor: pointer;
    transition: height 300ms, width 300ms;
    -webkit-animation: fadein 1000ms;
            animation: fadein 1000ms;
}
.mainOverlay>div:nth-of-type(odd) {
    height: 10vh;
    width: 2px;
}
.mainOverlay>div:nth-of-type(odd):hover {
    width: 100%;
}
.mainOverlay>div:nth-of-type(even) {
    width: 10vh;
    height: 2px;
}
.mainOverlay>div:nth-of-type(even):hover {
    height: 100%;
}
.mainOverlay>div:nth-of-type(1) {
    pointer-events: none;
    top: 0;
}
.mainOverlay>div:nth-of-type(2) {
    display: none;
    right: 0;
}
.mainOverlay>div:nth-of-type(3) {
    pointer-events: none;
    bottom: 0;
}
.mainOverlay>div:nth-of-type(4) {
    display: none;
    left: 0;
}
.spawnedText {
    position: absolute;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    font-weight: 100;
    z-index: 50;
    color: white;
    border: 1px solid white;
    border-radius: 100%;
    width: 15vmin;
    height: 15vmin;
    font-size: 3vmin;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    pointer-events: none;
    opacity: 0;
    transition: left 100ms linear, top 100ms linear, opacity 100ms linear;
    mix-blend-mode: difference;
}
.showSpawn {
    opacity: 1;
}
.tinyMenu {
    position: absolute;
    z-index: 1;
    color: white;
    top: 0;
    font-size: 10vmin;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
}
.tinyMenu>p {
    mix-blend-mode: difference;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    display: -webkit-flex;
    display: flex;
    height: 10vh;
    width: 10vw;
}
.tinyMenu>ul {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    font-size: 4vmin;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    height: 10vh;
    width: 90vw;
    -webkit-transform: translateX(-100vw);
            transform: translateX(-100vw);
    background: white;
    transition: all 200ms ease;
}
.tinyMenu>ul>li {
    color: black;
}
.tinyMenu>ul.showTinyMenu {
    -webkit-transform: translateX(0);
            transform: translateX(0);
}
@media screen and (min-width: 1025px) {
    .showSpawn {
        opacity: 1;
    }
    .mainOverlay>div:nth-of-type(1) {
        pointer-events: all;
    }
    .mainOverlay>div:nth-of-type(3) {
        pointer-events: all;
    }
    .mainOverlay>div:nth-of-type(2) {
        display: block;
    }
    .mainOverlay>div:nth-of-type(4) {
        display: block;
    }
    .tinyMenu {
        display: none;
    }
}
.transitionWrap {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background: white;
}
@-webkit-keyframes homet {
    0% {
        left: 0;
        width: 0;
    }
    65% {
        left: 0;
        width: 150vw;
    }
    100% {
        left: 0;
        width: 0;
        -webkit-transform: translateX(100vw);
                transform: translateX(100vw);
    }  
}
@keyframes homet {
    0% {
        left: 0;
        width: 0;
    }
    65% {
        left: 0;
        width: 150vw;
    }
    100% {
        left: 0;
        width: 0;
        -webkit-transform: translateX(100vw);
                transform: translateX(100vw);
    }  
}
@-webkit-keyframes aboutt {
    0% {
        top: 0;
        height: 0;
    }
    65% {
        top: 0;
        height: 150vh;
    }
    100% {
        top: 0;
        height: 0;
        -webkit-transform: translateY(100vh);
                transform: translateY(100vh);
    }
}
@keyframes aboutt {
    0% {
        top: 0;
        height: 0;
    }
    65% {
        top: 0;
        height: 150vh;
    }
    100% {
        top: 0;
        height: 0;
        -webkit-transform: translateY(100vh);
                transform: translateY(100vh);
    }
}
@-webkit-keyframes portfoliot {
    0% {
        right: 0;
        width: 0;
    }
    65% {
        right: 0;
        width: 150vw;
    }
    100% {
        right: 0;
        width: 0;
        -webkit-transform: translateX(-100vw);
                transform: translateX(-100vw);
    }
}
@keyframes portfoliot {
    0% {
        right: 0;
        width: 0;
    }
    65% {
        right: 0;
        width: 150vw;
    }
    100% {
        right: 0;
        width: 0;
        -webkit-transform: translateX(-100vw);
                transform: translateX(-100vw);
    }
}
@-webkit-keyframes contactt {
    0% {
        bottom: 0;
        height: 0;
    }
    65% {
        bottom: 0;
        height: 150vh;
    }
    100% {
        bottom: 0;
        height: 0;
        -webkit-transform: translateY(-100vh);
                transform: translateY(-100vh);
    }
}
@keyframes contactt {
    0% {
        bottom: 0;
        height: 0;
    }
    65% {
        bottom: 0;
        height: 150vh;
    }
    100% {
        bottom: 0;
        height: 0;
        -webkit-transform: translateY(-100vh);
                transform: translateY(-100vh);
    }
}
.about {
    position: absolute;
    top: 15vh;
    color: white;
    width: 65vw;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}
.about>h1 {
    font-size: 10vmin;
    -webkit-animation: fadedown 1500ms;
            animation: fadedown 1500ms;
}
.about>section {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}
.about>section>div {
    -webkit-flex-basis: 95%;
            flex-basis: 95%;
    margin-top: 5vh;
}
.about>section>div>p {
    font-size: 4vmin;
    -webkit-animation: fadedown 1200ms;
            animation: fadedown 1200ms;
}
.about>section>div:nth-of-type(2)>p {
    font-size: 3.5vmin;
    -webkit-animation: fadedown 1300ms;
            animation: fadedown 1300ms;
}
.about>section>div>ul {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    opacity: 0;
    /* animation: name duration timing-function delay iteration-count direction fill-mode; */
    -webkit-animation: fadedown 1200ms forwards;
            animation: fadedown 1200ms forwards;
}
.about>section>div>ul>li {
    font-size: 4vmin;
}
.about>section>div>ul>li:nth-child(1) {
    color: rgb(137, 210, 255);
}
.about>section>div>ul>li:nth-child(2) {
    color: rgb(116, 255, 218);
}
.about>section>div>ul>li:nth-child(3) {
    color: rgb(255, 144, 124);
}
.about>section>div>ul>li:nth-child(4) {
    color: rgb(255, 172, 255);
}
.about>section:nth-of-type(2) {
    width: 100%;
    margin-top: 15%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}
.resumeButton {
    border: 2px solid white;
    padding: 10px;
    font-size: 4vmin;
    transition: all 200ms;
    -webkit-animation: fadedown 1000ms;
            animation: fadedown 1000ms;
}
@media screen and (min-width: 1025px) {
    .about>section>div {
        -webkit-flex-basis: 45%;
                flex-basis: 45%;
    }
    .about>section>div>p {
        font-size: 3vmin;
    }
    .about>section>div:nth-of-type(2)>p {
        font-size: 3vmin;
    }
    .about>section>div>ul>li {
        font-size: 3vmin;
    }
    .resumeButton {
        font-size: 3vmin;
    }
}
.portfolio {
    width: 75vw;
    height: 75vh;
    display: -webkit-flex;
    display: flex;
    overflow-y: auto;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding-top: 0;
    cursor: ns-resize;
    -webkit-animation: fadein 1500ms;
            animation: fadein 1500ms;
}
.portfolio::-webkit-scrollbar {
    background: rgb(17,17,17);
    width: 5px;
    -webkit-animation: fadeleft 1200ms;
            animation: fadeleft 1200ms;
}
.portfolio::-webkit-scrollbar-thumb {
    background: yellow;
}
.spotlite {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    min-height: 0px;
    margin-bottom: 20vh;
    word-break: break-word;
}
.spotlite>div {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center;
}
.spotlite>h1 {
    text-transform: uppercase;
    font-size: 10vmin;
    mix-blend-mode: difference;
    -webkit-animation: fadeleft 1500ms;
            animation: fadeleft 1500ms;
}
.spotlite>div>a>img {
    width: 65vw;
    height: auto;
    -webkit-animation: fadeleft 1200ms;
            animation: fadeleft 1200ms;
}
.spotlite>div>ul {
    font-weight: 100;
    -webkit-animation: fadeleft 1800ms;
            animation: fadeleft 1800ms;
}
.spotlite>div>p {
    text-align: right;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    -webkit-animation: fadeleft 1950ms;
            animation: fadeleft 1950ms;
}
.spotlite>div>p>a {
    margin-right: 2vw;
    transition: color 100ms;
}
.spotlite>div>p>a:hover {
    color: yellow;
}
@media screen and (min-width: 1025px) {
    .portfolio {
        width: 65vw;
        height: 65vh;
        padding-top: 10vh;
    }
    .spotlite {
        -webkit-flex-direction: column;
                flex-direction: column;
        height: 65vh;
        margin-bottom: 0;
    }
    .spotlite>div>a>img {
        width: 25vw;
    }
}
.contact {
    position: absolute;
    width: 65vw;
    height: 85vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
}
h1>span {
    color: yellow;
}
.topSec {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    height: 85%;
}
.topSec>div {
    width: 100%;
    padding: 10px;
}
.topSec>div>p {
    height: 90%;
    font-family: 'Kanit', sans-serif;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    color: white;
    -webkit-animation: fadeup 1200ms;
            animation: fadeup 1200ms;
}
.topSec>div>p>a {
    color: white;
    font-size: 4vmin;
    text-decoration: underline;
    -webkit-animation: fadeup 1600ms;
            animation: fadeup 1600ms;
    transition: color 100ms;
}
.formHead > h1 {
    font-size: 10vmin;
    line-height: 10vmin;
    margin-bottom: 1vmin;
    -webkit-animation: fadeup 1100ms;
            animation: fadeup 1100ms;
}
form {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-animation: fadeup 1500ms;
            animation: fadeup 1500ms;
}
form>*{
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    font-weight: 100;
    border: 2px solid rgba(255, 255, 255, 0.7);
    color: white;
    padding: 5px;
    -webkit-flex-basis: 40%;
            flex-basis: 40%;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    margin: 5px;
}
form>textarea {
    resize: none;
    height: 50%;
    overflow-y: scroll;
    border: 2px solid rgba(255, 255, 255, 0.7);
}
form>textarea::-webkit-scrollbar {
    display: none;
}
.submitBtn {
    border: 2px solid rgba(255, 255, 255, 0.7);
    color: white;
    width: 5rem;
    margin: 10px 10px 10px 5px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: all 200ms;
    -webkit-animation: fadeup 2000ms;
            animation: fadeup 2000ms;
}
.contactButtons {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-animation: fadeup 1900ms;
            animation: fadeup 1900ms;
}
.fa {
    font-size: 9vmin;
    text-decoration: none;
    color: white;
    transition: color 100ms;
}
.resumeButton:hover, .submitBtn:hover {
    border: 2px solid black;
    background: yellow;
    color: black;
}
.fa:hover, .topSec>div>p>a:hover {
    color: yellow;
}
.hideform {
    opacity: 0;
    pointer-events: none;
}
.loading,
.loading:after,
.loading:before,
.done {
    border-radius: 50%;
}
.loading,
.done {
    color: #57d68c;
    font-size: 11px;
    text-indent: -99999em;
    position: absolute;
    margin: 10% 10%;
    width: 10em;
    height: 10em;
    box-shadow: inset 0 0 0 0.5em;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
.loading:after,
.loading:before,
.done:after {
    position: absolute;
    content: '';
}
.loading:before {
    width: 5.2em;
    height: 10.2em;
    background: rgb(17,17,17);
    border-radius: 10.2em 0 0 10.2em;
    top: -0.1em;
    left: -0.1em;
    -webkit-transform-origin: 5.1em 5.1em;
    transform-origin: 5.1em 5.1em;
    -webkit-animation: load2 2s infinite ease 1.5s;
    animation: load2 2s infinite ease 1.5s;
}
.loading:after {
    width: 5.2em;
    height: 10.2em;
    background: rgb(17,17,17);
    border-radius: 0 10.2em 10.2em 0;
    top: -0.1em;
    left: 4.9em;
    -webkit-transform-origin: 0.1em 5.1em;
    transform-origin: 0.1em 5.1em;
    -webkit-animation: load2 2s infinite ease;
    animation: load2 2s infinite ease;
}
.done {
    -webkit-animation: growin 5s forwards;
    animation: growin 5s forwards;
}
.done:after {
    width: 2em;
    height: 4em;
    top: 2em;
    left: 3.5em;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border-bottom: 1em solid #57d68c;
    border-right: 1em solid #57d68c;
}
input {
    transition: background-color 100ms;
}
.errorRed {
    background: rgba(245, 48, 48, 0.248);
}
@-webkit-keyframes load2 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
@keyframes load2 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
@-webkit-keyframes growin {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes growin {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@media screen and (min-width: 1025px) {
    .contact {
        height: auto;
        -webkit-justify-content: center;
                justify-content: center;
    }
    .topSec {
        -webkit-flex-direction: row;
                flex-direction: row;
    }
    .topSec>div {
        width: 50%;
    }
    .contactButtons {
        width: 25%;
    }
    .fa {
        font-size: 6vmin;
    }
    .loading,
    .done {
        margin: 0 25%;
        position: relative;
    }
    form {
        width: 85%;
    }
}
