.portfolio {
    width: 75vw;
    height: 75vh;
    display: flex;
    overflow-y: auto;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 0;
    cursor: ns-resize;
    animation: fadein 1500ms;
}
.portfolio::-webkit-scrollbar {
    background: rgb(17,17,17);
    width: 5px;
    animation: fadeleft 1200ms;
}
.portfolio::-webkit-scrollbar-thumb {
    background: yellow;
}
.spotlite {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    min-height: 0px;
    margin-bottom: 20vh;
    word-break: break-word;
}
.spotlite>div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}
.spotlite>h1 {
    text-transform: uppercase;
    font-size: 10vmin;
    mix-blend-mode: difference;
    animation: fadeleft 1500ms;
}
.spotlite>div>a>img {
    width: 65vw;
    height: auto;
    animation: fadeleft 1200ms;
}
.spotlite>div>ul {
    font-weight: 100;
    animation: fadeleft 1800ms;
}
.spotlite>div>p {
    text-align: right;
    flex-basis: 100%;
    animation: fadeleft 1950ms;
}
.spotlite>div>p>a {
    margin-right: 2vw;
    transition: color 100ms;
}
.spotlite>div>p>a:hover {
    color: yellow;
}
@media screen and (min-width: 1025px) {
    .portfolio {
        width: 65vw;
        height: 65vh;
        padding-top: 10vh;
    }
    .spotlite {
        flex-direction: column;
        height: 65vh;
        margin-bottom: 0;
    }
    .spotlite>div>a>img {
        width: 25vw;
    }
}