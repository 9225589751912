.contact {
    position: absolute;
    width: 65vw;
    height: 85vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
h1>span {
    color: yellow;
}
.topSec {
    display: flex;
    flex-wrap: wrap;
    height: 85%;
}
.topSec>div {
    width: 100%;
    padding: 10px;
}
.topSec>div>p {
    height: 90%;
    font-family: 'Kanit', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    animation: fadeup 1200ms;
}
.topSec>div>p>a {
    color: white;
    font-size: 4vmin;
    text-decoration: underline;
    animation: fadeup 1600ms;
    transition: color 100ms;
}
.formHead > h1 {
    font-size: 10vmin;
    line-height: 10vmin;
    margin-bottom: 1vmin;
    animation: fadeup 1100ms;
}
form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    animation: fadeup 1500ms;
}
form>*{
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    font-weight: 100;
    border: 2px solid rgba(255, 255, 255, 0.7);
    color: white;
    padding: 5px;
    flex-basis: 40%;
    flex-grow: 1;
    margin: 5px;
}
form>textarea {
    resize: none;
    height: 50%;
    overflow-y: scroll;
    border: 2px solid rgba(255, 255, 255, 0.7);
}
form>textarea::-webkit-scrollbar {
    display: none;
}
.submitBtn {
    border: 2px solid rgba(255, 255, 255, 0.7);
    color: white;
    width: 5rem;
    margin: 10px 10px 10px 5px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: all 200ms;
    animation: fadeup 2000ms;
}
.contactButtons {
    width: 100%;
    display: flex;
    justify-content: space-around;
    animation: fadeup 1900ms;
}
.fa {
    font-size: 9vmin;
    text-decoration: none;
    color: white;
    transition: color 100ms;
}
.resumeButton:hover, .submitBtn:hover {
    border: 2px solid black;
    background: yellow;
    color: black;
}
.fa:hover, .topSec>div>p>a:hover {
    color: yellow;
}
.hideform {
    opacity: 0;
    pointer-events: none;
}
.loading,
.loading:after,
.loading:before,
.done {
    border-radius: 50%;
}
.loading,
.done {
    color: #57d68c;
    font-size: 11px;
    text-indent: -99999em;
    position: absolute;
    margin: 10% 10%;
    width: 10em;
    height: 10em;
    box-shadow: inset 0 0 0 0.5em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
  }
.loading:after,
.loading:before,
.done:after {
    position: absolute;
    content: '';
}
.loading:before {
    width: 5.2em;
    height: 10.2em;
    background: rgb(17,17,17);
    border-radius: 10.2em 0 0 10.2em;
    top: -0.1em;
    left: -0.1em;
    -webkit-transform-origin: 5.1em 5.1em;
    -moz-transform-origin: 5.1em 5.1em;
    -ms-transform-origin: 5.1em 5.1em;
    -o-transform-origin: 5.1em 5.1em;
    transform-origin: 5.1em 5.1em;
    -webkit-animation: load2 2s infinite ease 1.5s;
    -moz-animation: load2 2s infinite ease 1.5s;
    -o-animation: load2 2s infinite ease 1.5s;
    animation: load2 2s infinite ease 1.5s;
}
.loading:after {
    width: 5.2em;
    height: 10.2em;
    background: rgb(17,17,17);
    border-radius: 0 10.2em 10.2em 0;
    top: -0.1em;
    left: 4.9em;
    -webkit-transform-origin: 0.1em 5.1em;
    -moz-transform-origin: 0.1em 5.1em;
    -ms-transform-origin: 0.1em 5.1em;
    -o-transform-origin: 0.1em 5.1em;
    transform-origin: 0.1em 5.1em;
    -webkit-animation: load2 2s infinite ease;
    -moz-animation: load2 2s infinite ease;
    -o-animation: load2 2s infinite ease;
    animation: load2 2s infinite ease;
}
.done {
    -webkit-animation: growin 5s forwards;
    -moz-animation: growin 5s forwards;
    -o-animation: growin 5s forwards;
    animation: growin 5s forwards;
}
.done:after {
    width: 2em;
    height: 4em;
    top: 2em;
    left: 3.5em;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    border-bottom: 1em solid #57d68c;
    border-right: 1em solid #57d68c;
}
input {
    transition: background-color 100ms;
}
.errorRed {
    background: rgba(245, 48, 48, 0.248);
}
@-webkit-keyframes load2 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
@keyframes load2 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
}
@keyframes growin {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 1;
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@media screen and (min-width: 1025px) {
    .contact {
        height: auto;
        justify-content: center;
    }
    .topSec {
        flex-direction: row;
    }
    .topSec>div {
        width: 50%;
    }
    .contactButtons {
        width: 25%;
    }
    .fa {
        font-size: 6vmin;
    }
    .loading,
    .done {
        margin: 0 25%;
        position: relative;
    }
    form {
        width: 85%;
    }
}